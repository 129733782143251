import snakeCaseKeys from 'snakecase-keys';
import Utils from './utils';

export default class MetaPixel {
  constructor(metaPixelId, version) {
    this.metaPixelId = metaPixelId;
    this.version = version;
    this.blockedParams = [
      'additional_parameters.billing_address',
      'additional_parameters.shipping_address',
      'filters.loan_features',
    ];
    if (window.fbq) {
      window.fbq('init', this.metaPixelId);
    }
  }

  track(
    eventType,
    eventId,
    eventName,
    eventData,
    userData = {},
    thirdPartyIdsData = {},
  ) {
    const payload = this.formatPayload(
      eventData,
      userData,
      thirdPartyIdsData,
    );
    this._send(eventType, eventId, eventName, payload);

    const eventQualification = MetaPixel.getEventQualification(payload.data);
    if (eventQualification && eventName === 'PageView') {
      this._send('trackSingleCustom', eventId, eventQualification, payload);
    }
  }

  static getEventQualification(data) {
    if (data
      && data.additional_parameters
      && data.additional_parameters.form_name === 'scb-credit-card-application'
      && data.additional_parameters.form_step === 'success'
      && data.country === 'sg'
      && data.channel === 'credit-cards'
      && data.provider === 'standard-chartered'
    ) {
      return 'API.LeadGeneration.CreditCard.SCB';
    }

    return null;
  }

  formatPayload(eventData, userData, thirdPartyIdsData) {
    const formattedEventData = snakeCaseKeys(eventData);

    // Drop event parameters that goes against Meta Business Tool Terms
    this.blockedParams.forEach((key) => Utils.removeField(formattedEventData, key));

    return {
      data: formattedEventData,
      user: snakeCaseKeys(userData),
      sent_at: new Date().toISOString(),
      version: this.version,
      _ids: thirdPartyIdsData,
    };
  }

  _send(eventType, eventId, eventName, payload) {
    return window.fbq(
      eventType,
      this.metaPixelId,
      eventName,
      payload,
      { eventID: eventId },
    );
  }
}
